import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { breakpoints } from 'styles/variables';

interface LinkProps {
  name: string;
  href: string;
  icon?: ReactNode;
}

const base = css`
  display: flex;

  margin-left: 20px;

  text-decoration: none;

  &:first-of-type {
    margin-left: 0;
  }

  transition: 200ms opacity ease-in-out;

  &:hover {
    opacity: 0.5;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-left: 40px;
  }
`;

const LinkElement = styled.a`
  ${base};
`;

const Icon = styled.div`
  svg {
    position: relative;

    margin-right: 8px;

    path {
      fill: #404040;
    }

    @media (min-width: ${breakpoints.md}) {
      top: 3px;
    }
  }
`;

const Lnk = styled(AniLink)`
  ${base};
`;

export const HeaderLink = ({ name, href, icon, className }: LinkProps) => {
  const isLink = typeof href !== 'undefined';
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(href || '');

  if (isExternal) {
    return (
      <LinkElement href={href} target="_blank" rel={'noopener noreferrer'} className={className}>
        {icon && <Icon>{icon}</Icon>}
        {name}
      </LinkElement>
    );
  }

  return (
    <>
      <Lnk paintDrip to={href} hex="#000" className={className}>
        {icon && <Icon>{icon}</Icon>}
        {name}
      </Lnk>
    </>
  );
};
