import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./vjs-trampolin.css"

export const VideoPlayer = ( props ) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const playerRef = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      });
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-trampolin vjs-big-play-centered object-cover" />
    </div>
  );
}

export default VideoPlayer