import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { breakpoints, variables } from 'styles/variables';
import { responsiveFont } from 'styles/utils';
import { Container } from 'components/container/Container';

import { Button } from 'components/button/Button';

interface WorkProps {
  children: ReactNode;
  workTitle: string;
  allWorkTitle: string;
}

const WorkRoot = styled.div`
  background-color: #eae7df;
`;

const Wrapper = styled.div`
  padding: 0px;
  paddin-top: 10px;
  padding-bottom: 60px;
`;

const SectionTitle = styled.p`
  ${responsiveFont(38, 44)};

  font-family: ${variables.font.familyHeading};
  font-weight: 500;
  padding-top: 100px;
`;

const TitleSection = styled.div`
  data-sal: 'slide-up';
  data-sal-delay: 0;
  data-sal-duration: 700;
  data-sal-easing: 'ease';
  padding-bottom: 40px;
`;

export const Work = ({ children, workTitle, allWorkTitle }: WorkProps) => {
  if (!workTitle) {
    return (
      <WorkRoot>
        <Container>
          <Wrapper>{children}</Wrapper>
        </Container>
      </WorkRoot>
    );
  }
  return (
    <WorkRoot>
      <Container>
        <Wrapper>
          <TitleSection>
            <SectionTitle>{workTitle}</SectionTitle>
            {/*<Button name={allWorkTitle} href="/work"></Button>*/}
          </TitleSection>
          {children}
        </Wrapper>
      </Container>
    </WorkRoot>
  );
};
